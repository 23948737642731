import React from 'react'

const Navigation = () => (
  <div className="container">
    <div className="column">
      <ul className="navigation">
        <li className="active">Dashboard</li>
        <li>Data Vault</li>
        <li>Request Credentials</li>
        <li>My Dapps</li>
      </ul>
    </div>
  </div>
)

export default Navigation
